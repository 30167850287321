<template>
  <v-container class="ma-0 pa-0">
    <v-card class="ma-0 pa-0">
      <v-toolbar dense color="secondary" dark>
        Autorizar Pedido
        <v-spacer></v-spacer>
        <v-btn @click="saveinfo" small color="success">Procesar</v-btn>
      </v-toolbar>

      <v-row class="mt-2 mx-2" >
        <v-col cols="12" >
          <p>Nombre: {{ nomuser }}  Email: {{email}}</p>

          <!-- ANTICIPO Y NUEVO TOTAL -->
          <v-row class="ma-1" v-if="docpost != ''">

            <v-col cols="12" xm="12" md="12" sm="12" >
              <p class="ma-0 font-weight-bold" > Anticipo: $300. </p>
              <p class="ma-0 font-weight-bold blue--text" > Total Surtido: $ {{ ntotalPedSur }}</p>
              <p class="ma-0 font-weight-bold red--text" >Pendiente a depositar: $ {{ nTotalPedido }} </p>
            </v-col>
          </v-row>
        
          <v-file-input
            click:clear='limpiarfoto'
            outlined
            dense
            small-chips
            v-model="files"
            :rules="rules"
            accept="image/png, image/jpeg, image/bmp"
            hint="Sube un archivo con el comprobante"
            label="Sube el comprobante"
            @change="cargar()"
          ></v-file-input>
        </v-col>

        <v-col cols="12" >
          <v-card>
                <v-img :src="logo" contain max-height="200"></v-img>
                </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>

import axios from 'axios'
import { mapActions, mapGetters } from 'vuex'

export default {
  data () {
    return {
      email: "",
      nomuser:'',

      email1:'',
      email2:'',
      
      loading: false,
      iddocum: '',
      numdoc: '',
      fecha:'',
      total:0.0,
      divisa:'',
      nombreImagen:'',

      ctadeps: '',
      nModo: 1,
      logo: '',
      logourl:'',
      fotopago2: '',

      fileLogo: '',
      files: [],
      imagen1: '',
      rules: [
        value => !value || value.size < 200000 || 'El logo debe medir menos de 200KB !'
      ],
      docpost: '',
      ntotalPedSur : 0.00,
      nAnticipo: 300.00,
      nTotalPedido: 0.00,
      niddocumPed :''
    }
  },
  created () {
    console.log("params", this.$route.params)
    this.fotopago2 = this.$route.params.info.fotopago2

    this.docpost = this.getDocumento.docpost
    this.consultar()

    if (this.$route.params.info.statusped == 0) {
      this.nModo = 1

      this.$http.get('api/v1/logos.list').then(response => {
        this.logo = response.body.Logourl
        this.logourl = response.body.Logourl

        // Mostramos foto actual si la imagen existe
        if (this.fotopago2 != '') {
          this.logo = this.fotopago2
        }
      }, error => {
        console.log(error)
      })
    } else {
      this.nModo = 2
    }
    this.cargarInfo()
  },

  computed: {
    ...mapGetters('documento', ['getDocumento']),
    ...mapGetters('tema', ['getLogourl']),
    ...mapGetters('Login', ['getdatosUsuario', 'getCIA', 'getCliente'])
  },

  methods: {
    ...mapActions('documento', ['addDocumento']),
    consultar() {
      this.docpost = this.getDocumento.docpost
      // CUENTAS A DEPOSITAR
      this.$http.get('auth/api/v1/formatos').then(response => {
        // console.log(response)
        this.ctadeps = response.data.Ctadeps
        // this.email2 = response.body.Email2
      }).catch(err => { console.log(err) })

      //Docpost es tiponum
      let cTipodoc = this.docpost.substring(0,2)
      let cNumdoc = this.docpost.substring(2,12)
      //API AXIOS api/v2
      var url = process.env.VUE_APP_OPTIONS_ROOT.replace("/tienda3","/api/v2/")
      var uri = url + 'documento.get'
      // console.log("url completa", uri)
       
      let payload = {
        "tipodoc": cTipodoc,
        "numdoc" : cNumdoc
      }

      // console.log("dget Docuemnto nota", payload)
      this.loading = true
      let token = localStorage.tlaKey
           
      axios.post( uri , payload ,{ headers: { 'Content-Type': 'application/json' ,'Authorization': `bearer ${token}`}
      }).then(response =>  {
        console.log("Response cambiar statusped37498327498729479238", response)
        if (response.data.error == null){
          this.ntotalPedSur = (response.data.result.Docum.importe - response.data.result.Docum.descuento + response.data.result.Docum.impuesto1).toFixed(2)
          this.nTotalPedido = (this.ntotalPedSur - this.nAnticipo).toFixed(2)
          
          this.niddocumPed = response.data.result.Docum.iddocum
          console.log("addDocuemnto", response.data.result)
          this.addDocumento(response.data.result)
        }
        
        // this.$router.push({name: 'segped'})
      }).catch(error => {
        console.log(error)
      }).finally(() => this.loading = false)
     
    },

    cargarInfo () {

      console.log("getdocumento",this.getDocumento)
      // console.log("getdatosUsuario",this.getdatosUsuario)
      
      this.email = this.getdatosUsuario.Email
      this.nomuser = this.getdatosUsuario.Nomuser
      this.iddocum = this.getDocumento.iddocum
      this.numdoc = this.getDocumento.numdoc
      this.fecha = this.getDocumento.fecha
      this.total = this.getDocumento.total
      this.logourl = this.getLogourl
      this.divisa = this.getDocumento.divisa


      this.$http.get('api/v1/email.list').then(response => {
        // console.log(response)
        this.email1 = response.body.Email1
        this.email2 = response.body.Email2
      }).catch(err => { console.log(err) })

      this.$http.get('auth/api/v1/formatos').then(response => {
        // console.log(response)
        this.ctadeps = response.data.Ctadeps
        // this.email2 = response.body.Email2
      }).catch(err => { console.log(err) })




      console.log(this.getDocumento.iddocum)
      this.iddocum = this.getDocumento.iddocum

      this.fotopago2 = this.$route.params.info.fotopago2
      // console.log('modo', this.nModo)
      // console.log('fotopago2', this.fotopago2)

      if (this.nModo == 2) {
        // VUE_APP_OPTIONS_ROOT=https://saitmd3.com/tienda3
        // let cUrlWeb = process.env.VUE_APP_OPTIONS_ROOT

        // let cUrlWeb ="https://saiterp.com/cosmeticoszalie/tienda3/"
        // let urlpago1 = cUrlWeb.replace("tienda3/", "fotossait/");
        // // Hacemos un ciclo para cargar las imagenes
        this.logo = this.fotopago2
        console.log('logo src', this.fotopago2)
      }
    },

    limpiarfoto () {
      console.log("limpiarfoto", this.logourl)
      this.src = this.logourl
    },

    cargar () {
      // console.log(this.files)
      if (this.files !== null) {
        // console.log("hola")
  	      this.getBase64(this.files)
      }

      this.logo = this.logourl
	    },

    getBase64 (file) {
	      var me = this
	      var reader = new FileReader()
	      reader.readAsDataURL(file)
	      reader.onload = function () {
	        me.imagen1 = reader.result
	        me.logo = reader.result
	         // console.log(reader.result);
	      }
      this.nombreImagen = this.files.name
	  },

    subirfoto () {
      console.log(this.files)
      this.nombreImagen = this.files.name
      this.size = this.files.size
      this.getBase64(this.files)
      // this.subirimg(this.files)
    },

    saveinfo () {
      	// this.subirimg()
       	this.loading = true
       	console.log('name file', this.files.name)

       	const formDataLogo = new FormData()
	      // se crea el objeto y se le agrega como un apendice el archivo
	      formDataLogo.append('file', this.files)
	    
      // console.log(formDataLogo)
	      // console.log("nombreImagen", this.nombreImagen)

       	// SUBIR LA IMAGEN AL SERVIDOR
      this.$http.post('auth/api/v1/imagenes', formDataLogo, { headers: { 'Content-Type': 'multipart/form-data' } }).then(function (response) {
        console.log('Se cargó la imagen PONER SNACK BAR ', response)
        this.$router.push({ name: 'segped' })
      }).catch(function (error) {
				  console.log(error)
      })

      this.cambiarstatus()
      this.sendmail()
    },

    
    sendmail(){

      if (this.getDocumento.divisa == 'P') {
        this.moneda = 'MXN'
      } else {
        this.moneda = 'USD'
      }

      console.log("nombreimagen ", this.nombreImagen)
      // Corificicar iddocum
      const iddocumcifrado = base64.encode(this.iddocum)
      const url = this.$http.options.root.replace('tienda3', 'consultacoti/')
        
      const urlweb = this.$http.options.root.replace('http://localhost:8099/', 'https://grupoazalie.com/')
      const urlfotopago2 = urlweb.replace('tienda3', 'fotossait/' + this.nombreImagen)
        

      this.loading = true
      const payload = {
        subject: "Pago del Pedido "+ this.numdoc,

        email: this.email,
        email1: this.email1,
        email2: this.email2,

        nomuser: this.nomuser,
        logo: this.logourl,
        c2a_link: url + iddocumcifrado,
        
        numdoc: this.numdoc,
        ctadeps: this.ctadeps,
        fecha: this.fecha,
        total: this.total,
        divisa: this.divisa,
        nomcli: this.getCliente.nomcli,
        layout: "pagar2.html",
        fotolink: urlfotopago2,
      }
      
      console.log('Sendamil payload', payload)
      this.$http.post('auth/api/v1/sendmail6', payload).then(response => {
        console.log('enviando Correo sendmail6', response)
        this.loading= false
      }).catch(error => { console.log(error) })

    },


    cambiarstatus () {
      // API AXIOS api/v2
      var url = process.env.VUE_APP_OPTIONS_ROOT.replace('/tienda3', '/api/v2/')
      var uri = url + 'seg.fotopago2'
      console.log('url completa', uri)

      const payload = {
        iddocum: this.iddocum,
        fotopago2: this.files.name.toLowerCase()
      }

      console.log('Cambiar status payload', payload)
      this.loading = true
      const token = localStorage.tlkey
      axios.put(uri, payload, { headers: { 'Content-Type': 'application/json', Authorization: `bearer ${token}` } }).then(response => {
        console.log('Response cambiar statusped')
        // this.$router.push({name: 'segped'})
      }).catch(error => {
        console.log(error)
      }).finally(() => this.loading = false)
    }

  }
}
</script>
